import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  FaCarAlt,
  FaAddressBook,
  FaBook,
  FaDollarSign,
  FaRoad,
  FaMotorcycle,
  FaClipboardList,
  FaFileAlt,
  FaWalking,
  FaPhoneAlt,
  FaEnvelope,
  FaUserAlt,
  FaRocketchat,
  FaFileInvoice,
  FaInfoCircle,
  FaShoppingBag,
} from "react-icons/fa";
import { MdOutlineCarRental } from "react-icons/md";
import {
  ApiCallDeleteMethod,
  ApiCallGetMethod,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
} from "../../constant/base";
import { BsCreditCard2FrontFill } from "react-icons/bs";

export const getAllLeads = () => {
  return ApiCallGetMethod("api/all/leads/get");
};
export const getSingleLeads = () => {
  return ApiCallGetMethod("api/dealerweb/market");
};
export const updateStatus = async (body) => {
  const { id } = body;
  return ApiCallUploadMethodJson(`api/dealerweb/market/update/${id}`, "PATCH", {
    market_status: body?.status,
  });
};
export const uploadActivityLeads = async (body) => {
  return ApiCallUploadMethodJson(`lead/activity `, "POST", {
    ...body,
  });
};
export const updateActivityLeads = async (body) => {
  return ApiCallUploadMethodJson(`lead/activity/${body?.id} `, "PATCH", {
    ...body,
  });
};
export const deleteLeadActivities = (ids) => {
  return ApiCallDeleteMethod(`lead/activity/${ids}`, "DELETE");
};
export const getActivityLeads = (body) => {
  const { id, leadTypeNumber } = body;
  return ApiCallGetMethod(`lead/detail/${id}/${leadTypeNumber}`);
};
export const getActivityLeadsFields = (body) => {
  return ApiCallGetMethod(`lead/activity/fields`);
};
export const getBookapoinment = () => {
  return ApiCallGetMethod("api/dealerweb/bookapointments");
};
export const getSingleBookapoinment = (id) => {
  return ApiCallGetMethod(`api/dealerweb/bookapointment/single/${id}`);
};
export const getSingleCarfax = (id) => {
  return ApiCallGetMethod(`api/dealerweb/carfax/single/${id}`);
};
export const updateBookapoinment = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/bookapointment/update/${id}`,
    "PATCH",
    { status },
    false
  );
};

export const getContactUs = async () => {
  return ApiCallGetMethod("api/dealerweb/contactus");
};
export const getSingleContactUs = (id) => {
  return ApiCallGetMethod(`api/dealerweb/contactus/single/${id}`);
};
export const updateContactUs = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/contactus/update/${id}`,
    "PATCH",
    { status },
    false
  );
};

export const getCarFinder = () => {
  return ApiCallGetMethod("api/dealerweb/carfinders");
};
export const getSingleCarFinder = (id) => {
  return ApiCallGetMethod(`api/dealerweb/carfinder/single/${id}`);
};
export const updateCarFinder = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/carfinder/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const getValueYourTrade = () => {
  return ApiCallGetMethod("api/dealerweb/valueyourtrades");
};
export const getSingleValueYourTrade = (id) => {
  return ApiCallGetMethod(`api/dealerweb/valueyourtrade/single/${id}`);
};
export const updateValueYourTrade = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/valueyourtrade/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const getTestDrive = () => {
  return ApiCallGetMethod("api/dealerweb/testdrives");
};
export const getSingleTestDrive = (id) => {
  return ApiCallGetMethod(`api/dealerweb/testdrive/single/${id}`);
};
export const updateTestDrive = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/testdrive/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const getOrderPart = () => {
  return ApiCallGetMethod("api/dealerweb/orderparts");
};
export const getSingleOrderPart = (id) => {
  return ApiCallGetMethod(`api/dealerweb/orderpart/single/${id}`);
};
export const updateOrderPart = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/orderpart/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const getServiceApoiment = () => {
  return ApiCallGetMethod("api/dealerweb/service_apointments");
};
export const getSingleServiceApoiment = (id) => {
  return ApiCallGetMethod(`api/dealerweb/service_apointment/${id}`);
};
export const updateServiceApoiment = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/service_apointment/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const getFinancials = () => {
  return ApiCallGetMethod("api/dealerweb/financials");
};
export const getSingleFinancial = (id) => {
  return ApiCallGetMethod(`api/dealerweb/financial/single/${id}`);
};
export const updateFinancial = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/financial/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updatePhysicalLead = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/physical/lead/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updateRealTestDrive = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/realtestdrive/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updateCargurus = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/cargurus/status/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updateCraigslist = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `/api/leads/craigslist/${id}/status`,
    "PATCH",
    { status },
    false
  );
};

export const getLeadComment = (value) => {
  const { type, id } = value;
  return ApiCallGetMethod(
    `lead/dealership/comment?lead_type=${type}&frk_lead_type_id=${id}`
  );
};

export const addLeadComment = (body) => {
  return ApiCallUploadMethodJson(
    `attach/lead/dealership/comment`,
    "POST",
    body
  );
};
export const updateLeadComment = (body) => {
  return ApiCallUploadMethodJson(
    `reform/lead/dealership/comment`,
    "PATCH",
    body
  );
};
export const updateCarfax = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/carfax/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updatetextUs = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/textus/update/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const updatemoreInfo = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/moreInfo/${id}`,
    "PATCH",
    { status },
    false
  );
};
export const marketingLead = (body) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/market/add`,
    "POST",
    body,
    false
  );
};
// follow section api
export const getSeller = () => {
  return ApiCallGetMethod("api/dealership/seller/list/to/assign");
};
export const addSellerFollow = (body) => {
  return ApiCallUploadMethodJson(
    `api/v1/lead/assign/${body.frk_lead_type_id}/${body.lead_type}`,
    "POST",
    body
  );
};

export const replyEmailLead = (body) => {
  return ApiCallUploadMethodJson(`api/lead_mails`, "POST", body);
};

export const ResendReplyEmailLead = ({ body, id }) => {
  return ApiCallUploadMethodJson(`api/lead_mails/${id}`, "PATCH", body);
};

export const deleteReplyEmailLead = (ids) => {
  return ApiCallDeleteMethod(`api/lead_mails/${ids?.id}`, "DELETE");
};

export const getReplyEmailLead = (typeFile) => {
  return ApiCallGetMethod(
    `api/lead_mails?lead_id=${typeFile?.id}&lead_type=${
      typeFile?.type === 22 ? "autotrader" : "cargurus"
    }`
  );
};

export const getSeeSellerFollowed = (id, type) => {
  return ApiCallGetMethod(`api/lead/negotiate/${id}/${type}`);
};

export const physicalLeadCustomerSignUp = async (value) => {
  console.log(value);
  const formData = await new FormData();
  formData.append("f_name", value?.f_name);
  formData.append("l_name", value?.l_name);
  if (
    value?.full_name &&
    value?.full_name !== null &&
    value?.full_name !== ""
  ) {
    formData.append("full_name", value?.full_name || "");
  }
  formData.append("mobile", value?.mobile);
  if (value?.city && value?.city !== null && value?.city !== "") {
    formData.append("city", value?.city);
  }
  if (value?.province && value?.province !== null && value?.province !== "") {
    formData.append("province", value?.province.value);
  }
  if (value?.email && value?.email !== null && value?.email !== "") {
    formData.append("email", value?.email);
  }
  if (value?.address && value?.address !== null && value?.address !== "") {
    formData.append("address", value?.address);
  }
  if (value?.gender && value?.gender !== null && value?.gender !== "") {
    formData.append("gender", value?.gender);
  }
  if (
    value?.birthdate &&
    value?.birthdate !== null &&
    value?.birthdate !== ""
  ) {
    formData.append("birthdate", value?.birthdate);
  }
  // if (value?.frk_city && value?.frk_city !== null && value?.frk_city !== "") {
  //   formData.append("frk_city", value?.frk_city?.value);
  // }
  if (
    value?.postalcode &&
    value?.postalcode !== null &&
    value?.postalcode !== ""
  ) {
    formData.append("postalcode", value?.postalcode);
  }
  if (
    value?.driver_license_NO &&
    value?.driver_license_NO !== null &&
    value?.driver_license_NO !== ""
  ) {
    formData.append("driver_license_NO", value?.driver_license_NO);
  }
  if (
    value?.driver_license_expire_date &&
    value?.driver_license_expire_date !== null &&
    value?.driver_license_expire_date !== ""
  ) {
    formData.append(
      "driver_license_expire_date",
      value?.driver_license_expire_date
    );
  }
  if (
    value?.driver_license_issue_date &&
    value?.driver_license_issue_date !== null &&
    value?.driver_license_issue_date !== ""
  ) {
    formData.append(
      "driver_license_issued_date",
      value?.driver_license_issue_date
    );
  }

  if (value?.driver_license_photo && value?.driver_license_photo !== null) {
    formData.append("driverLicenseImage", value?.driver_license_photo);
    formData.append("driver_license_photo", value.driver_license_photo);
  }
  if (
    value?.aparteman_no &&
    value?.aparteman_no !== null &&
    value?.aparteman_no !== ""
  ) {
    formData.append("aparteman_no", value?.aparteman_no);
  }
  if (value?.company_name && value?.company_name !== null) {
    formData.append("company_name", value?.company_name);
  }
  if (value.bcid && value.bcid !== null) {
    formData.append("bcid", value.bcid);
  }
  if (
    value.customer_authentication_method &&
    value.customer_authentication_method !== null
  ) {
    formData.append(
      "customer_authentication_method",
      value.customer_authentication_method?.value
    );
  }
  return ApiCallUploadMethodFormData(
    "api/dealership/physical/lead/signup",
    "POST",
    formData
  );
  // return ApiCallUploadMethodJson(
  //   "api/dealership/real/test/drive/signup",
  //   "POST",
  //   value
  // );
};
export const physicalLeadCustomerSignUpSeller = async (value) => {
  const formData = await new FormData();
  formData.append("f_name", value.f_name);
  formData.append("l_name", value.l_name);
  formData.append("full_name", value.full_name || "");
  formData.append("mobile", value.mobile);
  formData.append("city", value.city);
  // formData.append("province", value.province);
  if (value?.province && value?.province !== null && value?.province !== "") {
    formData.append("province", value?.province.value);
  }
  if (value.email && value.email !== null && value.email !== "") {
    formData.append("email", value.email);
  }
  if (value.address && value.address !== null && value.address !== "") {
    formData.append("address", value.address);
  }
  if (value.gender && value.gender !== null && value.gender !== "") {
    formData.append("gender", value.gender);
  }
  if (value.birthdate && value.birthdate !== null && value.birthdate !== "") {
    formData.append("birthdate", value.birthdate);
  }
  // if (value.frk_city && value.frk_city !== null && value.frk_city !== "") {
  //   formData.append("frk_city", value.frk_city?.value);
  // }
  if (
    value.postalcode &&
    value.postalcode !== null &&
    value.postalcode !== ""
  ) {
    formData.append("postalcode", value.postalcode);
  }
  if (
    value.driver_license_NO &&
    value.driver_license_NO !== null &&
    value.driver_license_NO !== ""
  ) {
    formData.append("driver_license_NO", value.driver_license_NO);
  }
  if (
    value.driver_license_expire_date &&
    value.driver_license_expire_date !== null &&
    value.driver_license_expire_date !== ""
  ) {
    formData.append(
      "driver_license_expire_date",
      value.driver_license_expire_date
    );
  }
  if (
    value.driver_license_issue_date &&
    value.driver_license_issue_date !== null &&
    value.driver_license_issue_date !== ""
  ) {
    formData.append(
      "driver_license_issued_date",
      value.driver_license_issue_date
    );
  }

  if (value.driver_license_photo && value.driver_license_photo !== null) {
    formData.append("driverLicenseImage", value.driver_license_photo);
    formData.append("driver_license_photo", value.driver_license_photo);
  }
  if (
    value.aparteman_no &&
    value.aparteman_no !== null &&
    value.aparteman_no !== ""
  ) {
    formData.append("aparteman_no", value.aparteman_no);
  }
  if (value.company_name && value.company_name !== null) {
    formData.append("company_name", value.company_name);
  }
  if (value.bcid && value.bcid !== null) {
    formData.append("bcid", value.bcid);
  }
  if (
    value.customer_authentication_method &&
    value.customer_authentication_method !== null
  ) {
    formData.append(
      "customer_authentication_method",
      value.customer_authentication_method?.value
    );
  }
  return ApiCallUploadMethodFormData(
    "api/seller/physical/lead/signup",
    "POST",
    formData
  );
  // return ApiCallUploadMethodJson(
  //   "api/dealership/real/test/drive/signup",
  //   "POST",
  //   value
  // );
};
export const postPhysicalLead = async (body) => {
  return ApiCallUploadMethodJson(
    "api/dealerweb/physical/lead/add",
    "POST",
    body
  );
};
export const updateCommercialCreditApplication = ({ id, status }) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/commercial/credit/application/update/${id}`,
    "PATCH",
    { status },
    false
  );
};

export const leadTypeIconAndName = (
  type,
  how_it_made = null,
  row,
  handleGetData,
  userInfo
) => {
  const leadRoutingOpen = [
    250, 814, 914, 426, 712, 370, 429, 260, 942, 952, 962, 1, 583,
  ];
  const isLeadRoutingOpen = leadRoutingOpen.includes(
    userInfo?.dealershipInfo?.id
  );

  switch (type) {
    case "1":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Value your trade</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaDollarSign />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "2":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Car finder</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaCarAlt />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "3":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Financial</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaFileAlt />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "4":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip className="p-0 m-0">Book an appointment</Tooltip>
            }>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaBook />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "5":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Contact us</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaAddressBook />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "6":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip className="p-0 m-0">Service appointment</Tooltip>
            }>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaClipboardList />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "7":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Part(s) order</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaMotorcycle />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "8":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Test drive</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaRoad />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "9":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          {how_it_made === "1" ? (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">{"Walking lead"}</Tooltip>}>
              <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
                <FaWalking />
              </i>
            </OverlayTrigger>
          ) : how_it_made === "2" ? (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">{"Calling lead"}</Tooltip>}>
              <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
                <FaPhoneAlt />
              </i>
            </OverlayTrigger>
          ) : +how_it_made === 3 ? (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">{"E-mail lead"}</Tooltip>}>
              <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
                <FaEnvelope />
              </i>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">{"Admin lead"}</Tooltip>}>
              <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
                <FaUserAlt />
              </i>
            </OverlayTrigger>
          )}
        </div>
      );

    case "10":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Test drive</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaRoad />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "11":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Cargurus</Tooltip>}>
            <i style={{ width: "4.5rem", color: "#69747C" }}>
              <div>
                <img
                  src="/icons/cargurus.png"
                  alt="icon"
                  className=""
                  style={{ width: "4.5rem" }}
                />
              </div>
            </i>
          </OverlayTrigger>
        </div>
      );

    case "12":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">More Info</Tooltip>}>
            <i style={{ width: "1.5rem", color: "#69747C" }}>
              <FaInfoCircle />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "13":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Sell Us Your Car</Tooltip>}>
            <i style={{ fontSize: "1.5rem", color: "#69747C" }}>
              <FaDollarSign />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "14":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Text Us</Tooltip>}>
            <i style={{ width: "1.5rem", color: "#69747C" }}>
              <FaRocketchat />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "15":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Craigslist</Tooltip>}>
            <i style={{ width: "4.5rem", color: "#69747C" }}>
              <FaFileInvoice />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "16":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Carfax</Tooltip>}>
            <div>
              <img
                src="/images/carfax-1.svg"
                alt=""
                style={{ width: "90px", height: "40px", color: "#69747C" }}
              />
            </div>
          </OverlayTrigger>
        </div>
      );
    case "17":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Market</Tooltip>}>
            <div>
              {/* <BuyNow_Icon /> */}
              <img
                src="/images/BuyNow_Icon.svg"
                alt=""
                style={{ width: "70px", height: "40px", color: "#69747C" }}
              />
            </div>
          </OverlayTrigger>
        </div>
      );
    case "18":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Rent</Tooltip>}>
            <i style={{ width: "4.5rem", color: "#69747C" }}>
              <MdOutlineCarRental size={30} />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "19":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Quote</Tooltip>}>
            <i style={{ width: "4.5rem", color: "#69747C" }}>
              <FaShoppingBag size={30} />
            </i>
          </OverlayTrigger>
        </div>
      );
    case "21":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={
              <Tooltip className="p-0 m-0">
                Commercial Credit Application
              </Tooltip>
            }>
            <i style={{ width: "4.5rem", color: "#69747C" }}>
              {/* <FaShoppingBag size={30} /> */}
              <BsCreditCard2FrontFill size={30} />
            </i>
          </OverlayTrigger>
        </div>
      );

    case "22":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          {isLeadRoutingOpen && (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">Auto Trader</Tooltip>}>
              <i style={{ width: "4.5rem", color: "#69747C" }}>
                {/* <FaShoppingBag size={30} /> */}
                {/* <FaCar fontSize="12px" />
              <FaArrowsAltH fontSize="12px" />
              <FaCar fontSize="12px" /> */}
                <img
                  alt=""
                  style={{ width: "25px" }}
                  src="/images/images.jpg"
                />
              </i>
            </OverlayTrigger>
          )}
        </div>
      );
    case "23":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          {isLeadRoutingOpen && (
            <OverlayTrigger
              placement="bottom"
              trigger={["hover", "focus"]}
              overlay={<Tooltip className="p-0 m-0">Carpages</Tooltip>}>
              <i style={{ width: "4.5rem", color: "#69747C" }}>
                {/* <FaShoppingBag size={30} /> */}
                {/* <FaCar fontSize="12px" />
              <FaArrowsAltH fontSize="12px" />
              <FaCar fontSize="12px" /> */}
                <img
                  alt=""
                  style={{ width: "25px" }}
                  src="/images/carpages-logo.png"
                />
              </i>
            </OverlayTrigger>
          )}
        </div>
      );
    case "25":
      return (
        <div
          onClick={() => {
            handleGetData({
              data: row?.original,
              id: row?.original?.id,
            });
          }}
          className="p-0 m-0 d-inline-flex justify-content-center w-100">
          <OverlayTrigger
            placement="bottom"
            trigger={["hover", "focus"]}
            overlay={<Tooltip className="p-0 m-0">Carfax Request</Tooltip>}>
            <div>
              <img
                src="/images/carfax-1.svg"
                alt=""
                style={{ width: "90px", height: "40px", color: "#69747C" }}
              />
            </div>
          </OverlayTrigger>
        </div>
      );
    default:
      return "";
  }
};
export const getApprisalData = async (id) => {
  return ApiCallGetMethod(`api/dealerweb/market/export/${id}`);
};
