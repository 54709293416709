import { OverlayTrigger, Popover } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import {
  FaCar,
  FaRoad,
  FaTasks,
  FaHandshake,
  FaThLarge,
  FaEnvelopeOpenText,
  FaTachometerAlt,
  FaUsers,
  FaRegEnvelopeOpen,
  FaCommentAlt,
  FaAngleDown,
  // FaSms,
  // FaEnvelopeOpen,
} from "react-icons/fa";
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";
import { useIsAuthState } from "../../hooks/useAuth";
import { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../context/socket";
import { useCookies } from "react-cookie";
import { IMAGE_CDN_DOMAIN } from "../../constant/base";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SellerDrawer = () => {
  const drawer = useDrawerState();
  const setDrawerState = useDrawerSetter();
  const user = useIsAuthState();
  const { socket, setSocket, chat } = useContext(SocketContext);
  const [{ token }] = useCookies(["token"]);
  const history = useHistory();
  const divPurchaseRef = useRef(null);
  const [isPurchaseExpanded, setIsPurchaseExpanded] = useState(false);
  const [unreadMassageCount, setunreadMassageCount] = useState("");
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        divPurchaseRef.current &&
        !divPurchaseRef.current.contains(event.target)
      ) {
        setIsPurchaseExpanded(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  useEffect(() => {
    // if (user?.user?.frk_role === 14) {
    if (!socket.connected && token) {
      setSocket((prevSocket) => {
        prevSocket.auth = { isPanel: true, token, isWeb: true };
        const newSocket = prevSocket.connect();
        newSocket.on("message:get", (newMessage) => {
          if (Notification.permission === "granted") {
            const room = newMessage.ChatRoom;
            const title = room.creator.is_guest
              ? room.creator_ip
              : room.creator.username;

            const icon = newMessage?.ChatRoom?.creator?.avatar
              ? `${IMAGE_CDN_DOMAIN}${newMessage.ChatRoom.creator.avatar}`
              : undefined;

            const notification = new Notification(title, {
              body: newMessage.message,
              icon,
            });

            notification.addEventListener("click", () => {
              window.focus();
              notification.close();
              history.push("/admin/chat", newMessage.roomId);
            });
          }
        });
        newSocket.on("rooms:unreadMessages", (unreadMassageCount) => {
          setunreadMassageCount(unreadMassageCount);
        });
        return newSocket;
      });

      setSocket((prevSocket) => prevSocket.connect());
    }

    // return () => setSocket(() => socket.disconnect());
    // }
  }, [history, setSocket, socket, token]);
  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-start sidenav-container ${drawer ? "sidenavOpen" : "sidenavClose"
        } `}
      onMouseOver={() => setDrawerState(true)}
      onMouseLeave={() => setDrawerState(false)}
    >
      <div className="p-2 m-0 mt-2 mb-5 sidebar_ul d-flex flex-column justify-content-center align-items-start">
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18
        ) &&
          user?.dealershipInfo?.id !== 260 && (
            <NavLink
              to="/admin"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              // activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaTachometerAlt className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                DASHBOARD
              </p>
            </NavLink>
          )}

        {user?.dealershipInfo?.id !== 369 && (
          <NavLink
            to="/admin/vehicle-managment"
            className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
            activeClassName="sidebar_navlink"
            onClick={() => {
              setDrawerState(false);
            }}
          >
            <FaCar className="sidebar_icons" />
            <p
              className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                } p-0 m-0`}
              style={{ fontSize: "1.1rem" }}
            >
              INVENTORY
            </p>
          </NavLink>
        )}
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18
        ) &&
          user?.dealershipInfo?.id !== 260 && (
            <div className="w-100 p-0 m-0">
              <div
                ref={divPurchaseRef}
                className=" text-decoration-none d-flex flex-wrap d-flex justify-content-start align-items-start w-100 p-0 m-0"
              >
                <div
                  className={`px-1 text-decoration-none icon-container sidebar_li d-flex justify-content-start align-items-center ${isPurchaseExpanded ? "active" : ""
                    }`}
                >
                  <span
                    className="p-0 m-0 d-flex w-100 flex-wrap "
                    onClick={(e) => {
                      setDrawerState(false);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                    }}
                  >
                    <div className="p-1 sidebar_li_2 d-flex flex-wrap m-0 w-100 align-items-center">
                      <img
                        src="/images/car.svg"
                        style={{
                          width: "40px",
                          height: "35px",
                        }}
                        className="car-icon"
                        alt="icon"
                      />

                      <img
                        src="/images/car2.svg"
                        style={{
                          width: "40px",
                          height: "35px",
                        }}
                        className="car2-icon"
                        alt="icon"
                      />

                      <p
                        className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                          } p-0 m-0`}
                        style={{
                          fontSize: "1.1rem",
                          marginLeft: "15px !important",
                        }}
                      >
                        <span style={{ marginLeft: "-10px" }}>Purchases</span>
                        <FaAngleDown style={{ marginLeft: "20px" }} />
                      </p>
                    </div>
                  </span>
                </div>
              </div>
              {isPurchaseExpanded && (
                <div>
                  <NavLink
                    to="/admin/purche-from-public"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="px-0 w-100 m-0">
                      <ul className="py-0 py-1 m-0 px-0">
                        <li className="px-2" style={{ fontSize: "14px" }}>
                          {" "}
                          Purchase From Public
                        </li>
                      </ul>
                    </ul>
                  </NavLink>
                  <NavLink
                    to="/admin/purchase-from-dealer"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="p-0 w-100 m-0">
                      {/* <strong
                          className=" p-0 m-0 d-flex justify-content-center blink_animation align-items-center "
                          style={{
                            width: "30px",
                            height: "30px",
                            fontSize: "17px",
                            fontWeight: "900 !important",
                            borderRadius: "5px",
                            position: "absolute",
                            zIndex: "2",
                            // top: "80.5%",
                            left: "20px",
                            color: "#28fdf3",
                          }}
                        >
                          new
                        </strong> */}
                      <li className="px-2 py-1" style={{ fontSize: "14px" }}>
                        Purchase From Dealer
                      </li>
                    </ul>
                  </NavLink>
                </div>
              )}
            </div>
          )}
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18) &&
          user?.dealershipInfo?.id !== 260 && (
            <NavLink
              to="/admin/seller-all-cars"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              {/* <i className="sidebar_icons d-flex align-items-center justify-content-center"> */}

              <FaHandshake className="sidebar_icons" />

              {/* </i> */}
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                DEAL
              </p>
            </NavLink>
          )}
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18) && (
            <NavLink
              to="/lead-center"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaEnvelopeOpenText className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                LEAD
              </p>
            </NavLink>
          )}
        {/* {user?.user?.frk_dealer_ship_id === 250 ||
        user?.user?.frk_dealer_ship_id === 1 ||
        user?.frk_dealer_ship_id === 369 ||
        user?.dealershipInfo?.id === 393 ? (
          // ||
          // user?.user?.frk_dealer_ship_id === 369
          <NavLink
            to="/market-center"
            className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
            activeClassName="sidebar_navlink"
            onClick={() => {
              setDrawerState(false);
            }}
          >
            <FaRegEnvelopeOpen className="sidebar_icons" />
            <p
              className={`${drawer || isPurchaseExpanded === true? "" : "d-none"} p-0 m-0`}
              style={{ fontSize: "1.1rem" }}
            >
              MARKET LEAD
            </p>
          </NavLink>
        ) : null} */}
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18) && (
            <NavLink
              to="/seller/customers"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaUsers className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                CUSTOMER
              </p>
            </NavLink>
          )}
        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18) && (
            <NavLink
              to="/admin/dealer-real-test-drive"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaRoad className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                TEST DRIVE
              </p>
            </NavLink>
          )}

        {(user.user.frk_role === 6 ||
          user.user.frk_role === 14 ||
          user.user.frk_role === 16 ||
          user.user.frk_role === 18) && (
            <NavLink
              to="/user-tasks"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaTasks className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                TASKS
              </p>
            </NavLink>
          )}
        {
          // (user?.user?.frk_role === 14|| user.user.frk_role === 16) &&
          user?.dealershipInfo?.has_chat === 1 ? (
            <NavLink
              to="/admin/chat"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaCommentAlt className="sidebar_icons" />
              <p
                className={`${drawer || isPurchaseExpanded === true ? "" : "d-none"
                  } p-0 m-0 d-flex flex-row justify-content-between w-100 align-items-center`}
                style={{ fontSize: "1.1rem" }}
              >
                CHAT{" "}
                {+chat.unreadRoomsCount !== 0 && (
                  <b
                    className=" p-0 m-0 d-flex justify-content-center align-items-center "
                    style={{
                      width: "25px",
                      height: "25px",
                      fontSize: "14px",
                      borderRadius: "5px",
                    }}
                  >
                    {chat.unreadRoomsCount}
                  </b>
                )}
              </p>
            </NavLink>
          ) : null
        }
      </div>
    </div>
  );
};

export default SellerDrawer;
